.head{
    padding: 10px;
    padding-top: 20px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.cart-div{
    position: absolute;
    width: 420px;
    right: 0;
    margin-top: 13px;
    min-height: 380px 
}
.mobileapp{
  display: flex;
  margin-left: 30px;
}
.gtp{
    margin-left: 5px;
}
.btns{
    display: flex;
    justify-content: space-between;
}
.btns1{
    border: 1px solid #7c003f;
    padding: 5px;
    width: 45%;
    border-radius: 20px;
    font-size: 12px;
    height: 28px;
    display: flex;
    justify-content: space-between;
}

.metlog{
   
    height: 45px;
    margin-top: -10px;
}
.metfd{
    display: flex;
  
}
.metfdname{
    font-size: 30px;
    margin-left: -5px;
    margin-top: -10px;
    font-weight: bold;
    color: #7c003f;
}
.signitem{
    display: flex;
    justify-content: space-between;
}
.signitem1{
    border: 1px solid black;
    padding: 5px;
    width: 20%;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    text-align: center;
}
@media(max-width:767px){
    .metfd{
        margin-top: 20px;
    }
}
