.profile{
    height: 750px;
    padding: 65px;
}

.profs{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
    margin-top: 30px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
  }