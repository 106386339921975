.pros{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
    margin-top: 30px;
    border-radius: 10px;
    padding: 20px;
  }
  .pross{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
    
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
   
  }
  .vima{
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .pros:hover{
    transform: scale(1.02);
    box-shadow:  black;
  }
  
  /* .bimage1{
    height: 100%;
    width: 100%;
    background-image: url(../Assets/images/int1.jpg)
  } */
.name{
    position: absolute;
    top: 324px;
    font-size: 50px;
    font-weight: 500;
    color: #ffffff;
}
.img2{
  position: absolute;
}
.about{
  padding: 65px;
  background-color: antiquewhite;
}
.service{
 padding: 65px;
 background-color: aliceblue;
}
.serv{

  padding: 65px;
  
 }
.conts1{
  padding: 65px;
  height: 680px;
}
.cshead{
 font-size: 49px;
 margin-top: 60px;
}
.cspara{
 font: size 22px;
 margin-top: 15px;
}
.contsm, .contsm1{
  padding: 55px;
}
.contsm1{
text-align: center;
}
.cusjo{
margin-top: 100px;
}
.yemm{
  font-size: 22px;
  margin-top: 30px;
}
.hrln{
height: 3px;
color: black;
}
.ymbt{
width: 100%;
height: 50px;
font-size: 22px;
color: black;
margin-top: 45px;
}
.footer{
  height: 680px;
  padding: 65px;
  background-color: black;
 }